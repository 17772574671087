.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
    font-family: Inter,ui-sans-serif,system-ui,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;  }
  
  .content {
    width: 90%;
    max-width: 500px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  
  .title {
    font-size: 50px;
    color: #333333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .subtitle {
    font-size: 16px;
    color: #666666;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    font-size: 14px;
    color: #333333;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input[type="text"],
  .form-group textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease;
  }
  
  .form-group input[type="text"]:focus,
  .form-group textarea:focus {
    outline: none;
    border-color: #8e2de2;
  }
  
  .form-group input[type="color"] {
    width: 50px;
    padding: 0;
    border: none;
    border-radius: 50%; /* Make the color input round */
  }
  
  .button-group {
    display: flex;
    justify-content: center;
  }
  
  .generate-btn {
    padding: 12px 20px;
    background: linear-gradient(to right, #8e2de2, #4a00e0);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
    font-size: 16px;
  }
  
  .generate-btn:hover {
    background: linear-gradient(to right, #8e2de2, #4a00e0);
  }
  
  .qr-code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin : 20px;

  }
  
  .qr-code {
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin : 20px;
  }